<template>
    <div class="report-footer px-5 pb-5">
        <div class="columns">
            <div class="column is-6 has-text-left">
                <slot></slot>
                <url-text :locale-key="'footer.text'"></url-text>
            </div>
            <div class="column is-6 has-text-right">
                {{ $t('footer.data_version', { data_version: data_version }) }}
            </div>
        </div>
    </div>
</template>

<script>
import UrlText from './UrlText.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        UrlText,
    },
    props: {
        pageNumber: {
            type: Number,
            default: null,
        },
    },
    computed: {
        ...mapGetters(['getFeatures']),
        data_version() {
            // transform 2024-06-01 into 06/2024
            return this.getFeatures.meta_2d_features_validity_date
                .split('-')
                .slice(0, 2)
                .reverse()
                .join('/')
        },
    },
}
</script>
