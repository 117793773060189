<template>
    <i18n :path="localeKey" tag="p">
        <template #url>
            <a :href="$t('url.rockestate_main')" target="_blank">
                {{ $t('url.rockestate_main') }}
            </a>
        </template>
    </i18n>
</template>

<script>
export default {
    props: {
        localeKey: {
            type: String,
            default: null,
        },
    },
}
</script>
